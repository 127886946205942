<template>
  <v-container>
    <div class="my-container1" v-if="contentReady">
      <div class="success-container" v-if="genuineProduct">
        <v-card class="mx-auto" max-width="344" v-if="!reveal2">
          <v-card-text>
            <div>
              <v-icon icon="mdi-package-variant-closed">
                mdi-package-variant-closed
              </v-icon>
              Pack Weight
            </div>
            <p class="display-1 text--primary">
              {{ productInfo.weight }} {{ productInfo.unit }}
            </p>
            <div class="text--primary">
              <v-icon color="teal">mdi-information-variant</v-icon>
              Compare the pack weight printed on the box to verify and continue
              to check genuineness of the product.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-list-item class="grow">
              <v-row
                align="center"
                justify="end"
                class="pa-2"
              >
                <!-- <v-icon class="mr-1">
                  mdi-share-variant
                </v-icon>
                <span class="subheading">45</span> -->
                <!-- <v-btn text class="" color="green accent-4" @click="reveal2 = true">
                  <v-icon left> mdi-certificate </v-icon>
                  View Certificate
                </v-btn> -->
                <v-btn text color="deep-purple accent-4" @click="reveal2 = true">
                  Continue
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
              <!-- <v-spacer></v-spacer> -->
            </v-list-item>
          </v-card-actions>
        </v-card>

        <v-fade-transition>
          <div v-if="reveal2" transition="fade-transition">
            <v-alert text border="left" :type="alertType">
              {{ alertMsg }}
            </v-alert>

            <div
              class="d-flex justify-space-between py-1"
              v-if="showProductDetails"
            >
              <v-card class="mx-auto" max-width="344">
                        <!-- V44 C Style &amp; V Style -->
                <!-- <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-text>
                      <div>Product Information</div>
                      <p class="text-h6 text--primary">
                        {{ productInfo.name }}
                      </p>
                    </v-card-text>
                  </div>
                </div> -->

                <v-card-text>
                  <div>Product Information</div>
                  <p class="text-h6 text--primary">
                    <!-- el·ee·mos·y·nar·y -->
                    {{ productInfo.name }}
                  </p>
                  <!-- <p>adjective</p> -->
                  <div>
                    <v-icon icon="mdi-package-variant-closed">
                      mdi-package-variant-closed
                    </v-icon>
                    Pack Weight
                  </div>
                  <p class="display-1 text--primary">
                    {{ productInfo.weight }} {{ productInfo.unit }}
                  </p>
                  <v-list class="transparent" dense>
                    <v-list-item
                      v-for="item in productDetails"
                      :key="item.name"
                    >
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle class="text-right">
                        {{ item.value }}
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <div class="text--primary pt-2">
                    <v-icon color="teal">mdi-information-variant</v-icon>
                    Compare the above pack weight with actual pack weight
                    to check genuineness of the product.
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-btn text color="green accent-4"
                    v-if="productInfo.hasCertificate"
                    @click="openCertificate"
                  >
                    <v-icon left> mdi-certificate </v-icon>
                    View COA
                  </v-btn>
                  <!-- <v-list-item class="grow">
                    <v-row
                      align="center"
                      justify="end"
                      class="pa-2"
                    >
                      <v-btn text color="green accent-4"
                        v-if="productInfo.hasCertificate"
                        @click="openCertificate"
                      >
                        <v-icon left> mdi-certificate </v-icon>
                        View COA
                      </v-btn>

                      <v-btn
                        text
                        color="deep-purple accent-4"
                        @click="reveal = !reveal"
                      >
                        View Details
                        <v-icon right>{{ reveal ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      </v-btn> -->

                      <!-- <v-btn
                        v-bind:href="'/api/v2/product/certificate?batch_no='
                        + productInfo.batchNumber"
                        target="_blank"

                        text
                        color="deep-purple accent-4"
                        @click="reveal = !reveal"
                      >
                        View Details
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="reveal = !reveal">
                        <v-icon>{{
                          reveal ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn> -->

                    <!-- </v-row>
                  </v-list-item> -->
                </v-card-actions>

                <!-- <v-expand-transition>
                  <div v-show="reveal">
                    <v-divider></v-divider>

                    <v-simple-table class="ma-3">
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="item in productDetails" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-expand-transition> -->
              </v-card>
            </div>
          </div>
        </v-fade-transition>
      </div>

      <div class="error-container" v-else>
        <v-alert text border="left" :type="alertType">
          {{ alertMsg }}
        </v-alert>
      </div>
    </div>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import productApi from '@/api/product';

const moment = require('moment');

export default {
  data: () => ({
    reveal: false,
    reveal2: true,
    snackbar: false,
    snackbarText: null,
    alertType: 'success',
    alertMsg: 'Genuine Product',
    showProductDetails: false,
    contentReady: false,
    genuineProduct: false,
    productDetails: [],
    productInfo: {
      name: 'Mask',
      desc1: 'Fold Flat - Pocket friendly style',
      image: 'mask.svg',
      weight: 0,
      unit: 'g',
      batchNumber: null,
      hasCertificate: false,
    },
    // forecast: [
    //   { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
    //   { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
    //   { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' },
    // ],
  }),
  created() {
    // console.log(this.$route.params.s)
    // this.fetchProductInfo(this.$route.query.s)
    const s = this.$route.query.s || this.$route.params.s;
    this.fetchProductInfo(s);
  },
  methods: {
    fetchProductInfo(qrData) {
      this.productDetails = [];
      productApi
        .findByQRCode2(qrData)
        .then((response) => {
          console.log(response);
          const res2 = response.data;
          if (res2.status === 200 && !res2.error) {
            const data = res2.result;
            const weight = data.weight ?? 0;

            this.productInfo.name = data.name;
            this.productInfo.desc1 = data.desc1;
            this.productInfo.image = data.imageName ?? 'mask.svg';
            this.productInfo.batchNumber = data.batchNumber;
            this.productInfo.hasCertificate = data.hasCertificate;

            const wInfo = this.formatWeight2(weight)
            this.productInfo.weight = wInfo.weight
            this.productInfo.unit = wInfo.unit

            this.productDetails.push({
              key: 'itemCode',
              name: 'Item Code',
              value: data.itemCode,
            });
            this.productDetails.push({
              key: 'batchNumber',
              name: 'Batch Number',
              value: data.batchNumber,
            });
            // this.productDetails.push({
            //   key: 'desc2',
            //   name: 'Approval',
            //   value: data.desc2,
            // });
            this.productDetails.push({
              key: 'transactionTime',
              name: 'Mfg. Date',
              value: moment.utc(data.transactionTime).format('DD-MM-yyyy'),
            });
            // this.productDetails.push({
            //   key: 'weight',
            //   name: 'Weight',
            //   value: `${this.productInfo.weight} ${this.productInfo.unit}`,
            // });

            this.showProductDetails = true;
            this.genuineProduct = true;
          } else {
            // print error
            this.alertType = 'error';
            this.alertMsg = 'Data not available for this QRC';
            this.genuineProduct = false;
          }
          // const dt = JSON.parse(data);
          // console.log(dt);

          this.contentReady = true;
        })
        .catch((err) => {
          console.log(err);
          this.snackbarText = err;
          this.snackbar = true;
        });
    },
    openCertificate() {
      if (this.productInfo.hasCertificate) {
        productApi.viewTestCertificate(this.productInfo.batchNumber)
      }
    },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
